<template>
  <div class="container">
    <div>
      <form>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Vos informations</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nom">
              <b-input type="text" v-model="form.name" placeholder="Votre nom" maxlength="50s" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse mail">
              <b-input type="email" v-model="form.email" placeholder="Votre adresse mail" maxlength="50" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Adresse postale">
              <b-input type="text" v-model="form.address" placeholder="Votre adresse postale" maxlength="60" :has-counter="false" required>
              </b-input>
            </b-field>
            <b-field label="Téléphone">
              <b-input v-model="form.phone" placeholder="Votre numéro de téléphone" maxlength="25" :has-counter="false" required>
              </b-input>
            </b-field>
          </section>
        </div>
      </form>
    </div>
    <div>
      <form>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">L'événement</p>
          </header>
          <section class="modal-card-body">
            <b-field>
              {{ myEvent.name }}
            </b-field>
            <b-field>
              {{ myEvent.game.name }}
            </b-field>
            <b-field>
              <strong>Prix total : {{ Number(myEvent.price).toFixed(2) }} CHF</strong>
            </b-field>
            <div v-show="!paidFor">
              <b-field>
                <strong>Frais paypal équivalent à 0,55CHF + 3,4%</strong>
              </b-field>
              <b-field>
                <div style="position: relative; z-index: 1;">
                  <div ref="paypal"></div>
                </div>
              </b-field>
            </div>
            <b-field v-show="paidFor">
              <h3> Merci pour votre inscription </h3>
            </b-field>
            <b-field>
            </b-field>
          </section>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import router from '@/router'
import notification from "@/mixins/notification";
export default {
  name: 'ShowEventValidation',
  data: () => ({
    errorOrder: false,
    form: {},
    loaded: false,
    paidFor: false,
    user: {}
  }),
  mixins:[notification],
  computed: {
    ...mapGetters(['myEvent'])
  },
  beforeMount() {
    this.$store.dispatch('getUser')
      .then((data) => {
        this.user = data;
        this.form = this.user;
      })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
  },
  mounted() {
    this.$store.commit('changeTmpEvent', false)
    this.$store.commit('EVENT_OK', false)
    const script = document.createElement('script')
    script.src = 'https://www.paypal.com/sdk/js?currency=CHF&client-id=AXzEHueFn4Lj8N15C4VEgAaVrqriil9pfITwCy4e0vOag2Nf74FkEIVj48z7yibf10JdXlVCHgl07QYc'
    script.addEventListener('load', this.setLoaded)
    document.body.appendChild(script)
  },
  methods: {
    async payEvent() {
      const data = {
        name: this.form.name,
        email: this.form.email,
        address: this.form.address,
        phone: this.form.phone,
        id: this.user.id
      }
      await this.$store.dispatch('updateAccount', data).then( () => {
        const events = {}
        events.id = this.myEvent.id
        const dataEvent = {}
        dataEvent.events = events
        this.$store.dispatch('payEvent', events)
          .then(() => {
            this.$store.commit('doneEvent', true)
            router.push({name: 'ConfirmationEvent'})
          }).catch(() => {
          this.showError('L\'inscription est impossible.')
        });
      }).catch(() => {
        this.showError('L\'inscription est impossible avec vos informations.')
      });
    },
    setLoaded: function () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Commande à ARENE DE DUEL : Inscription à un event :' + this.myEvent.name + ' & Player : ' + this.user.name,
                  amount: {
                    currency_code: 'CHF',
                    value: Number((this.myEvent.price * 1.034 + 0.55)).toFixed(2)
                  }
                }
              ]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            this.paidFor = true
            this.payEvent(this.myEvent)
          }
        })
        .render(this.$refs.paypal)
    }
  }
}
</script>
